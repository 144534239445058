import classNames from 'classnames';
import React from 'react';

import styles from '@/styles/components/LiveHint.module.scss';

const LiveHint: React.FC<{ className?: string }> = (props) => {
  return (
    <div className={classNames(styles.live, props.className)}>
      <em>•</em>
      <span>Live</span>
    </div>
  );
};

export default LiveHint;
