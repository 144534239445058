import classNames from 'classnames';
import Link from 'next/link';
import React from 'react';

import { date } from '@/helpers/date';
import { isMatter, isTag } from '@/helpers/isMatterOrTag';
import { usePlatform } from '@/helpers/platformContext';
import { MatterType, TagType } from '@/helpers/types';

import LiveHint from '@/components/LiveHint';

import styles from '@/styles/components/IndexPage/DesktopLargeItem.module.scss';

type PropsType = {
  item: MatterType | TagType;
  isActive: boolean;
  isSingle: boolean;
  onChange: (id: number) => void;
};

const DesktopLargeItem: React.FC<PropsType> = ({
  item,
  isActive,
  isSingle,
  onChange,
}) => {
  const isLive = usePlatform().live_config?.id === item.id;

  return (
    <div
      className={classNames(styles.item, {
        [styles.__active]: isActive,
        [styles.__single]: isSingle,
      })}
      onMouseEnter={() => onChange(item.id)}
    >
      {isLive && <LiveHint />}

      <Link href={item.path}>
        <a className={styles.link_cover}>
          <div className={styles.title}>
            {isMatter(item) && item.highlighted_title ? (
              <span className="italic">{item.highlighted_title}. </span>
            ) : null}
            {item.h1_title || item.title}
          </div>
          {isMatter(item) && (
            <>
              <div className={styles.lead}>
                {item.short_lead ? item.short_lead : item.lead}
              </div>
              <div className={styles.date}>
                {date(item.published_at || item.published_at_in_timezone.iso)}
              </div>
            </>
          )}
        </a>
      </Link>
      {isTag(item) && item.matters.length > 0 && (
        <>
          <div className={styles.date}>
            {date(item.matters[0].published_at_in_timezone.iso)}
          </div>
          <Link href={item.matters[0].path}>
            <a className={styles.tagMatterTitle}>{item.matters[0].title}</a>
          </Link>
        </>
      )}
    </div>
  );
};

export default DesktopLargeItem;
