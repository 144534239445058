import classNames from 'classnames';
import Link from 'next/link';
import React from 'react';

import { date } from '@/helpers/date';
import { MatterType } from '@/helpers/types';

import styles from '@/styles/components/IndexPage/DesktopSmallItem.module.scss';

type PropsType = {
  matter: MatterType;
  className?: string;
};

const DesktopSmallItem: React.FC<PropsType> = ({
  matter,
  className,
}) => {
  return (
    <Link href={matter.path}>
      <a
        className={classNames(
          styles.item,
          matter.image ? '' : styles._noImg,
          className,
        )}
      >
        {matter.image && (
          <div className={styles.imgContainer}>
            <img
              src={matter.image.image_800}
              alt={matter.title}
              className="aspect-[1.5] object-cover"
            />
          </div>
        )}
        <div className="relative z-[1]">
          <div className={styles.title}>
            {matter.highlighted_title ? (
              <span className="italic">{matter.highlighted_title}. </span>
            ) : null}
            {matter.title}
          </div>
          <div className={styles.lead}>
            {matter.short_lead ? matter.short_lead : matter.lead}
          </div>
          <div>
            {date(matter.published_at || matter.published_at_in_timezone.iso)}
          </div>
        </div>
      </a>
    </Link>
  );
};

export default DesktopSmallItem;
